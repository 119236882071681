<template>
  <div
    class="index-me-center"
  >
    <SLoading
      v-if="showRefreshLoading"
      show
      size="medium"
    />

    <!-- panel-wrap -->
    <div class="panel-wrap">
      <UserRightsPanel
        v-if="userRightsInfo"
        :rights-info="userRightsInfo"
      />
    </div>
    <div
      v-if="props.newsInfo"
      class="panel-wrap-news"
    >
      <NewsPanel
        :news-info="props.newsInfo"
        :route-info="props.routeInfo"
      />
    </div>
    <div class="panel-wrap__services">
      <MainServices
        :personal-info="props.personalInfo"
        @router-push="routerPush"
      />
      <UserAssetsBottom
        :ready-to-init="isPageDataReady"
        :bottom-tips-info="bottomTipsInfo"
        @router-push="routerPush"
      />
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from 'vue'

/* component */
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import UserRightsPanel from './UserRightsPanel/ModuleIndex.vue'
import MainServices from './MainServices.vue'
import NewsPanel from './NewsPanel.vue'
import UserAssetsBottom from './UserAssetsBottom/ModuleIndex.vue'

const props = defineProps({
  isPageDataReady: { type: Boolean, default: false },
  showRefreshLoading: { type: Boolean, default: false },
  personalInfo: { type: Object, default: () => {}  },
  userRightsInfo: { type: Object, default: null  },
  bottomTipsInfo: { type: Object, default: () => {} },
  newsInfo: { type: Object, default: () => {} },
  routeInfo: { type: Object, default: () => ({}) },
})

const emits = defineEmits([
  'routerPush',
  'pointsOrCouponJumpEvt',
  'loginSucRedirection',
])


/* ------ methods ------ */

const routerPush = (...args) => {
  emits('routerPush', ...args)
}
</script>

<style lang="less" scoped>
@w: 375/100vw;
@w2: 750/100vw;
.index-me-center {
  position: relative;
  z-index: @zindex-hack;

  .panel-wrap {
    //background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 37%);
    .spacing-top {
      margin-top: 0.2133rem;
    }
  }

  .panel-wrap__services {
    margin: 0  0.16rem 0.16rem;
    border-radius: 4px;
    overflow: hidden;
  }
  .tips-list {
    padding: 0 8/75rem 24/75rem;
    background: #FFF;

    .tips-arrow {
      display: flex;

      .arrow {
        flex: 1;
        justify-content: center;
        display: flex;
        align-items: center;

        .arrow-icon {
          border: 12/75rem solid transparent;
          border-bottom-color: #fff1d5;
          width: 12/75rem;
          height: 12/75rem;
          display: inline-block;
        }
      }
    }
  }
}
</style>
