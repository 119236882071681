import { 
  EXPIRE_GAME_MONEY_CACHE_KEY,
  ExpireTipsClickStatus,
} from 'public/src/pages/user_index/consts'
import { getLocalStorage } from 'public/src/pages/user_index/service/utils'
import { isLogin } from 'public/src/pages/common/utils'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager'

export const getUserAssetsBottomContent = ({
  readyToInit,
  expireTipsInfo,
  isHideModuleAction = false
}) => {
  const controls = {
    showExpireGameMoney: false,
    showExpireCouponTips: false,
    showExpirePointTips: false,
  }
  if (!readyToInit) {
    return controls
  }
  if (isHideModuleAction) {
    // 如果是显式隐藏模块，则隐藏后续模块
    return controls
  }
  initExpireGameMoneyTipsStatus({ controls, expireTipsInfo })
  if (controls.showExpireGameMoney) {
    return controls
  }
  initExpireCouponTipsStatus({ controls, expireTipsInfo })
  if (controls.showExpireCouponTips) {
    return controls
  }
  initExpirePointTipsStatus({ controls, expireTipsInfo })
  return controls
}

export const getExpireGameMoneyCacheKey = () => {
  const userId = isLogin() ? UserInfoManager.get({
    key: 'memberId',
    actionType: 'user-dashboard/user-assets-bottom',
  }) || '' : 'anonymous'
  return `${EXPIRE_GAME_MONEY_CACHE_KEY}_${userId}`
}

export const getExpireGameMoneyCache = () => {
  const cacheKey = getExpireGameMoneyCacheKey()
  return getLocalStorage(cacheKey, {
    status: ExpireTipsClickStatus.CAN_BE_CLOSED,
    closeTimes: 0,
  })
}

const initExpireGameMoneyTipsStatus = ({
  controls,
  expireTipsInfo,
}) => {
  if (!expireTipsInfo?.walletTip) return
  const { promptTip, clickFrequency: maxCloseTimes } = expireTipsInfo.walletTip
  if (!promptTip || !maxCloseTimes) return
  const expireTipCache = getExpireGameMoneyCache()
  if (expireTipCache.status === ExpireTipsClickStatus.NO_LONGER_SHOW) return
  const reachMaxCloseTimes = expireTipCache.closeTimes >= maxCloseTimes
  if (reachMaxCloseTimes) return
  const now = Date.now()
  controls.showExpireGameMoney = expireTipCache.closeTimes === 0 || now > expireTipCache.displayAfterTime
}

const initExpireCouponTipsStatus = ({
  controls,
  expireTipsInfo,
} = {}) => {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'initExpireCoupon' })
  const isClickCoupon = !!getLocalStorage(`${memberId}_couponHit`)
  const show = !!expireTipsInfo?.expireCouponTotal && !isClickCoupon
  controls.showExpireCouponTips = show
}

const initExpirePointTipsStatus = ({
  controls,
  expireTipsInfo,
} = {}) => {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'initExpireCoupon' })
  const isClickPoints = !!getLocalStorage(`${memberId}_pointsHit`)
  const show = !!expireTipsInfo?.expirePointTotal && !isClickPoints
  controls.showExpirePointTips = show
}


