// 预取数据的路由名称
const PREFETCH_DATA_ROUTES_NAME = [
  'config_index',
  'config_activity',
  'category'
]

// 其他页面预取数据的等待时间
const PREFETCH_WAIT_TIME = 3000

/**
 * Cache Key
 */
const CACHEKEY_DASHBOARD_BASE_PREFIX = 'DashboardBase#'
const CACHEKEY_USERINFO = 'UserInfo'
const CACHEKEY_IMG_PRELOAD_PREFIX = 'ImgPreload#'

export {
  PREFETCH_DATA_ROUTES_NAME,
  PREFETCH_WAIT_TIME,
  CACHEKEY_DASHBOARD_BASE_PREFIX,
  CACHEKEY_USERINFO,
  CACHEKEY_IMG_PRELOAD_PREFIX
}

export const COUPON_APPLY_FOR = {
  // 全部商品
  AllProducts: '1',
  // 部分商品
  SomeProducts: '2',
  // 部分品类
  SomeCategories: '3',
  // 减最低邮费
  MinimumPostageReduced: '5',
  // 免邮券
  FreeShipping: '6',
  // 选品页面
  ProductSelectionPage: '7',
  // 部分店铺的平台券
  SomeStoresFromPlatform: '8',
  // 指定运输方式减运费
  MinusFreightOnSpecificTransport: '9',
  // 会费券
  ClubCoupon: '10',
  // ab 价伪装券
  ABPrice: '101',
}

export const COUPON_OFF_TYPE = {
  // 减元
  Cash: '1',
  // 应付价折扣
  PercentOfPriceShouldPay: '5',
  // 原价折扣
  PercentOfOriginPrice: '2',
}

// 融合楼层用户权益类型
export const UserRightsFusionType = {
  // 付费会员
  SHEIN_CLUB_MEMBER: 1,
  // 超省卡
  SUPER_SAVE: 2,
}

export const SuperSaveCouponStatus = {
  // 待领取
  TO_COLLECT: 0,
  // 待使用
  TO_USE: 1,
}

export const EXPIRE_GAME_MONEY_CACHE_KEY = 'personal-center__expire-game-money'
export const ExpireTipsClickStatus = {
  // 不再展示
  NO_LONGER_SHOW: 1,
  // 可关闭
  CAN_BE_CLOSED: 2,
}

export const FooterRcmdListResolveStatus = {
  PENDING: 0,
  HAS_RCMD_LIST: 1,
  NO_RCMD_LIST: 2,
}
